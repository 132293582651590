"use strict"

export class multilines {
  #field
  #dataId
  #lines
  constructor({ field, dataId, data }) {
    this.#field = field
    this.#dataId = dataId
    this.#lines = new lines({ data })
  }
  get field() { return this.#field }
  get dataId() { return this.#dataId }
  get lines() { return this.#lines }
  set lines(data) { this.#lines = new lines({ data }) }
  executeInAllLines = async ({ func }) => await Promise.all(this.#lines.map((line, index) => func({ line, index })))
}

class lines extends Array {
  constructor({ data }) {
    class line {
      constructor({ data }) {
        Object.assign(this, data)
      }
    }
    super(...(data?.map(lineData => new line({ data: lineData })) || ''))
  }
}